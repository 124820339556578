import styled from "styled-components";

const LogoAnimation = styled.div`
    cursor: pointer;
    width: 493px;
    height: 78px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("logo.svg");
        :hover {
            delay: 0.5s;
            background-image: url("logoHover.svg");
        }
    }
    z-index: +1;
`;

function Logo( { onMouseEnter, onMouseLeave }) {
    return (
        <LogoAnimation onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}/>
    );
}

export default Logo;
