import { useState, useEffect } from "react";
import Logo from "./animatedLogo";
import RadioPlayer from "./audioPlayer";
import styled from "styled-components";

const images = [
    'hero-images/01.jpg',
    'hero-images/02.jpg',
    'hero-images/03.jpg',
    'hero-images/04.jpg',
    'hero-images/05.jpg',
    'hero-images/06.jpg',
    'hero-images/07.jpg',
    'hero-images/08.jpg',
    'hero-images/09.jpg',
    'hero-images/10.jpg',
    'hero-images/11.jpg',
  ];

const HeroContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: absolute;
    background-position: center;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.3;
`;

function Hero() {
    const [currentImage, setCurrentImage] = useState(null);
    const [slideshowInterval, setSlideshowInterval] = useState(null);
    const [isMuted, setIsMuted] = useState(true);
  
    useEffect(() => {
      const preloadedImages = [];
  
      images.forEach(image => {
        const img = new window.Image();
        img.src = image;
        preloadedImages.push(img);
      });
  
      return () => {
        clearInterval(slideshowInterval);
      };
    },[slideshowInterval]);
  
    const handleMouseEnter = () => {
        setIsMuted(false);
      setSlideshowInterval(
        setInterval(() => {
          const randomIndex = Math.floor(Math.random() * images.length);
          setCurrentImage(images[randomIndex]);
        }, 40)
      );
    };
  
    const handleMouseLeave = () => {
        setIsMuted(true);
      clearInterval(slideshowInterval);
      setCurrentImage(null);
    };

      return (
        <>
        <RadioPlayer muteState={isMuted} />
        <Logo onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
        <HeroContainer style={{
            backgroundImage: currentImage ? `url(${currentImage})` : null
        }}>

        </HeroContainer>
        </>
      );
    }
  
  export default Hero;