import ReactAudioPlayer from 'react-audio-player';

function RadioPlayer(props) {

    const {
        muteState
    } = props;

  return (
    <ReactAudioPlayer
    src="https://stream.radio.co/s91ac114e7/listen"
    autoPlay={true}
    muted={muteState}
    controls={false}
/>
  );
};

RadioPlayer.defaultProps = {
    muteState : true
};

export default RadioPlayer;