import Hero from "../components/hero";
import styled from "styled-components";

const PageContainer = styled.div`
    background: #000;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

function Home() {
    return (
        <PageContainer>
            <Hero />
        </PageContainer>
    );
    }

export default Home;